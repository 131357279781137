import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import planet from '../images/planet.svg';

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 50%;
`;

const Text = styled.div`
  margin-top: 1rem;
  margin-bottom: 7.5rem;
  text-align: center;
`;

const H1 = styled.h1`
  margin-bottom: 0.125rem;
`;

const NotFoundPage = () => (
  <Layout>
    <Container>
      <SEO title="Page not found" />
      <Img src={planet} />
      <Text>
        <H1>404 - Page not found</H1>
        <p>Looks like you've taken a wrong turn.</p>
      </Text>
    </Container>
  </Layout>
);

export default NotFoundPage;
